<template>
  <AppPage :name="'miaosha'">
    <div class="main">
      <div class="content">
        <div class="miaosha-content p-rel">
          <GoodsTab />
        </div>
      </div>
    </div>
    <div class="ml-20"></div>
    <div class="side">
      <AppQRCode />
      <VideoIntro />
      <AppComment />
      <ContactUs />
      <Copyright />
    </div>
  </AppPage>
</template>
<script>
export default {
  components: {
    GoodsTab: () => import("./components/GoodsTab.vue"),
  },
  data() {
    return {
      touchPageEnd: false,
      pageScrolling: false,
    };
  },
  created() {},
  computed: {},
  mounted() {
    // 监听页面滚动
    var self = this;

    // 采用事件节流方式监听页面滚动
    window.onscroll = function () {
      self.pageScrolling = true;
    };

    setInterval(function () {
      if (self.pageScrolling) {
        self.pageScrolling = false;

        var pageScrollTop = document.documentElement.scrollTop;
        var pageHeight = document.getElementById("app").offsetHeight;
        var borderPadding = 80;

        if (
          pageScrollTop + document.documentElement.offsetHeight >
          pageHeight - borderPadding
        ) {
          self.touchPageEnd = true;
        } else {
          self.touchPageEnd = false;
        }

        console.log("touchend:", self.touchPageEnd);
      }
    }, 500);
  },
  methods: {},
};
</script>
<style lang="css" scoped>
.main {
  width: 846px;
}
.content {
  background: white;
}
.side {
  width: 314px;
}
</style>
